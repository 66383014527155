<template>
  <div class="hero-img">
    <div class="hero-img__container">
      <div
        v-animate-onscroll="'animated backIn'"
        id="el-1"
        class="hero-img__el"
      >
        <img src="@/assets/img/hero-img/1.svg" alt="" />
      </div>
      <div
        v-animate-onscroll="'animated backIn delay-05'"
        id="el-2"
        class="hero-img__el"
      >
        <img src="@/assets/img/hero-img/2.svg" alt="" />
      </div>
      <div
        v-animate-onscroll="'animated backIn delay-10'"
        id="el-3"
        class="hero-img__el"
      >
        <img src="@/assets/img/hero-img/3.svg" alt="" />
      </div>
      <div
        v-animate-onscroll="'animated backIn delay-15'"
        id="el-4"
        class="hero-img__el"
      >
        <img src="@/assets/img/hero-img/4.svg" alt="" />
      </div>
      <div
        v-animate-onscroll="'animated backIn delay-20'"
        id="el-5"
        class="hero-img__el"
      >
        <img src="@/assets/img/hero-img/5.svg" alt="" />
      </div>
      <div
        v-animate-onscroll="'animated backIn delay-25'"
        id="el-6"
        class="hero-img__el"
      >
        <img src="@/assets/img/hero-img/6.svg" alt="" />
      </div>
      <div
        v-animate-onscroll="'animated backIn delay-30'"
        id="el-7"
        class="hero-img__el"
      >
        <img src="@/assets/img/hero-img/7.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-hero-img",
};
</script>

<style lang="scss">
.hero-img {
  position: relative;
  width: 647px;
  height: 430px;
  overflow: hidden;

  &__container {
    position: relative;
    top: 57px;
    left: 0;
    width: 647px;
    height: 430px;
  }

  &__el {
    position: absolute;
  }

  #el-1 {
    top: 0;
    left: 0;
  }
  #el-2 {
    top: -50px;
    left: 0;
    right: 0;
  }
  #el-3 {
    top: 12px;
    left: 45px;
  }
  #el-4 {
    left: 45px;
    top: 170px;
  }
  #el-5 {
    left: 205px;
    top: 170px;
  }
  #el-6 {
    bottom: 42px;
    left: 45px;
  }
  #el-7 {
    bottom: 68px;
    right: 0;
  }
}
</style>
